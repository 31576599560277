import { render, staticRenderFns } from "./pengguna.vue?vue&type=template&id=79ee818c&scoped=true"
import script from "./pengguna.vue?vue&type=script&lang=js"
export * from "./pengguna.vue?vue&type=script&lang=js"
import style0 from "./pengguna.vue?vue&type=style&index=0&id=79ee818c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79ee818c",
  null
  
)

export default component.exports
import {QCard,QCardSection,QInput,QBtn,QTooltip,QSeparator,QBtnGroup,QDialog,QCardActions,QImg,QSelect,QFile,QIcon,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QInput,QBtn,QTooltip,QSeparator,QBtnGroup,QDialog,QCardActions,QImg,QSelect,QFile,QIcon})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
